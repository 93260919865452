import React from 'react';
import "../styles/Repertory.scss";
import ScreenRepertory from './ScreenRepertory';
import MobileRepertory from './MobileRepertory';

function Repertory() {
  return <div className='repertory' id="repertorio">
    <ScreenRepertory />
    <MobileRepertory />
  </div>;
}

export default Repertory;
