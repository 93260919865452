import React, { useState } from 'react';
import AlmaLlanera from "../assets/AlmaLlanera.mp3";
import TuyoHeyDeSer from "../assets/TuyoHeyDeSer.mp3";
import DeCuba from "../assets/DeCuba.mp3";
import DonnaUbenza from "../assets/DonnaUbenza.mp3";
import ElGrillo from "../assets/ElGrillo.mp3";
import Befiehl from "../assets/Befiehl.mp3";
import Bruder from "../assets/Bruder.mp3";
import Heilig from "../assets/Heilig.mp3";
import OSalutaris from "../assets/OSalutaris.mp3";
import ReactModal from 'react-modal';
import { IoClose } from 'react-icons/io5';
import "../styles/Audios.scss";

function MobileAudios() {
    const [showModal, setShowModal] = useState(false);
    const [showModal1, setShowModal1] = useState(false);
    const [showModal2, setShowModal2] = useState(false);
    const [showModal3, setShowModal3] = useState(false);
    const [showModal4, setShowModal4] = useState(false);
    const [showModal5, setShowModal5] = useState(false);
    const [showModal6, setShowModal6] = useState(false);
    const [showModal7, setShowModal7] = useState(false);
    const [showModal8, setShowModal8] = useState(false);

    const handleOpenModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    const handleOpenModal1 = () => setShowModal1(true);
    const handleCloseModal1 = () => setShowModal1(false);

    const handleOpenModal2 = () => setShowModal2(true);
    const handleCloseModal2 = () => setShowModal2(false);

    const handleOpenModal3 = () => setShowModal3(true);
    const handleCloseModal3 = () => setShowModal3(false);

    const handleOpenModal4 = () => setShowModal4(true);
    const handleCloseModal4 = () => setShowModal4(false);

    const handleOpenModal5 = () => setShowModal5(true);
    const handleCloseModal5 = () => setShowModal5(false);

    const handleOpenModal6 = () => setShowModal6(true);
    const handleCloseModal6 = () => setShowModal6(false);

    const handleOpenModal7 = () => setShowModal7(true);
    const handleCloseModal7 = () => setShowModal7(false);

    const handleOpenModal8 = () => setShowModal8(true);
    const handleCloseModal8 = () => setShowModal8(false);

    function playSound() {
        document.getElementById('audio').play();
        document.removeEventListener('click', playSound);
    }
    document.addEventListener('click', playSound);

    return <div className='mobileAudios'>
        <h3 onClick={handleOpenModal}>- Alma llanera (arr. Liliana Cangiano)</h3>
        <ReactModal isOpen={showModal}
            style={{
                overlay: {
                    position: 'fixed',
                    zIndex: 1020,
                    top: 0,
                    left: 0,
                    width: '100vw',
                    height: '100vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    background: 'rgba(0, 0, 0, 0.671)'
                },
                content: {
                    display: 'flex',
                    height: '100vh',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    textAlign: 'center',
                    border: 'none',
                    color: '#fff5eb',
                    background: 'rgba(0, 0, 0, 0.671)'
                },
            }}>
            <h2 className='icon'><IoClose onClick={handleCloseModal} /></h2>
            <h2>Alma llanera (arr. Liliana Cangiano)</h2>
            <audio controls id="audio" portalClassName="modal">
                <source src={AlmaLlanera} type="audio/mp3" />
            </audio>
        </ReactModal>
        <h3 onClick={handleOpenModal1}>- Tuyo he'y se ser (vidala popular; arr. Marcelo Ortiz Roca)</h3>
        <ReactModal isOpen={showModal1}
            style={{
                overlay: {
                    position: 'fixed',
                    zIndex: 1020,
                    top: 0,
                    left: 0,
                    width: '100vw',
                    height: '100vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    background: 'rgba(0, 0, 0, 0.671)'
                },
                content: {
                    display: 'flex',
                    height: '100vh',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                    flexDirection: 'column',
                    border: 'none',
                    color: '#fff5eb',
                    background: 'rgba(0, 0, 0, 0.671)'
                },
            }}>
            <h2><IoClose onClick={handleCloseModal1} /></h2>
            <h2>Tuyo he'y se ser (vidala popular; arr. Marcelo Ortiz Roca)</h2>
            <audio controls id="audio">
                <source src={TuyoHeyDeSer} type="audio/mp3" />
            </audio>
        </ReactModal>

        <h3 onClick={handleOpenModal2}>- De Cuba para La Habana (anónimo; arr. Electo Silva)</h3>
        <ReactModal isOpen={showModal2}
            style={{
                overlay: {
                    position: 'fixed',
                    zIndex: 1020,
                    top: 0,
                    left: 0,
                    width: '100vw',
                    height: '100vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    background: 'rgba(0, 0, 0, 0.671)'
                },
                content: {
                    display: 'flex',
                    height: '100vh',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                    flexDirection: 'column',
                    border: 'none',
                    color: '#fff5eb',
                    background: 'rgba(0, 0, 0, 0.671)'
                },
            }}>
            <h2><IoClose onClick={handleCloseModal2} /></h2>
            <h2>De Cuba para La Habana (anónimo; arr. Electo Silva)</h2>
            <audio controls id="audio">
                <source src={DeCuba} type="audio/mp3" />
            </audio>
        </ReactModal>

        <h3 onClick={handleOpenModal3}>- Doña Ubensa (arr. Liliana Cangiano)</h3>
        <ReactModal isOpen={showModal3}
            style={{
                overlay: {
                    position: 'fixed',
                    zIndex: 1020,
                    top: 0,
                    left: 0,
                    width: '100vw',
                    height: '100vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    background: 'rgba(0, 0, 0, 0.671)'
                },
                content: {
                    display: 'flex',
                    height: '100vh',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                    flexDirection: 'column',
                    border: 'none',
                    color: '#fff5eb',
                    background: 'rgba(0, 0, 0, 0.671)'
                },
            }}>
            <h2><IoClose onClick={handleCloseModal3} /></h2>
            <h2>Doña Ubensa (arr. Liliana Cangiano)</h2>
            <audio controls id="audio">
                <source src={DonnaUbenza} type="audio/mp3" />
            </audio>
        </ReactModal>

        <h3 onClick={handleOpenModal4}>- El grillo è buon cantore (Josquin des Près)</h3>
        <ReactModal isOpen={showModal4} bodyOpenClassName={"ReactModal__Body--open"}
            style={{
                overlay: {
                    position: 'fixed',
                    zIndex: 1020,
                    top: 0,
                    left: 0,
                    width: '100vw',
                    height: '100vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    background: 'rgba(0, 0, 0, 0.671)',
                    transition: 'opacity 2000ms ease-in-out'
                },
                content: {
                    display: 'flex',
                    height: '100vh',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    border: 'none',
                    textAlign: 'center',
                    color: '#fff5eb',
                    background: 'rgba(0, 0, 0, 0.671)'
                },
            }}>
            <h2><IoClose onClick={handleCloseModal4} /></h2>
            <h2>El grillo è buon cantore (Josquin des Près)</h2>
            <audio controls id="audio">
                <source src={ElGrillo} type="audio/mp3" />
            </audio>
        </ReactModal>

        <h3 onClick={handleOpenModal5}>- Befiehl Du Deine Wege (Johann Sebastian Bach)</h3>
        <ReactModal isOpen={showModal5}
            style={{
                overlay: {
                    position: 'fixed',
                    zIndex: 1020,
                    top: 0,
                    left: 0,
                    width: '100vw',
                    height: '100vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    background: 'rgba(0, 0, 0, 0.671)'
                },
                content: {
                    display: 'flex',
                    height: '100vh',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    border: 'none',
                    textAlign: 'center',
                    color: '#fff5eb',
                    background: 'rgba(0, 0, 0, 0.671)'
                },
            }}>
            <h2><IoClose onClick={handleCloseModal5} /></h2>
            <h2>Befiehl Du Deine Wege (Johann Sebastian Bach)</h2>
            <audio controls id="audio">
                <source src={Befiehl} type="audio/mp3" />
            </audio>
        </ReactModal>

        <h3 onClick={handleOpenModal6}>- Brüder reicht die Hand zum Bunde (Wolfgang Amadeus Mozart)</h3>
        <ReactModal isOpen={showModal6}
            style={{
                overlay: {
                    position: 'fixed',
                    zIndex: 1020,
                    top: 0,
                    left: 0,
                    width: '100vw',
                    height: '100vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    background: 'rgba(0, 0, 0, 0.671)'
                },
                content: {
                    display: 'flex',
                    height: '100vh',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    border: 'none',
                    textAlign: 'center',
                    color: '#fff5eb',
                    background: 'rgba(0, 0, 0, 0.671)'
                },
            }}>
            <h2><IoClose onClick={handleCloseModal6} /></h2>
            <h2>Brüder reicht die Hand zum Bunde (Wolfgang Amadeus Mozart)</h2>
            <audio controls id="audio">
                <source src={Bruder} type="audio/mp3" />
            </audio>
        </ReactModal>

        <h3 onClick={handleOpenModal7}>- Heilig (Zum Sanctus) (Franz Schubert)</h3>
        <ReactModal isOpen={showModal7}
            style={{
                overlay: {
                    position: 'fixed',
                    zIndex: 1020,
                    top: 0,
                    left: 0,
                    width: '100vw',
                    height: '100vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    background: 'rgba(0, 0, 0, 0.671)'
                },
                content: {
                    display: 'flex',
                    height: '100vh',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    border: 'none',
                    textAlign: 'center',
                    color: '#fff5eb',
                    background: 'rgba(0, 0, 0, 0.671)'
                },
            }}>
            <h2><IoClose onClick={handleCloseModal7} /></h2>
            <h2>Heilig (Zum Sanctus) (Franz Schubert)</h2>
            <audio controls id="audio">
                <source src={Heilig} type="audio/mp3" />
            </audio>
        </ReactModal>

        <h3 onClick={handleOpenModal8}>- O Salutaris (Agnes Zimmermann)</h3>
        <ReactModal isOpen={showModal8}
            style={{
                overlay: {
                    position: 'fixed',
                    zIndex: 1020,
                    top: 0,
                    left: 0,
                    width: '100vw',
                    height: '100vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    background: 'rgba(0, 0, 0, 0.671)'
                },
                content: {
                    display: 'flex',
                    height: '100vh',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    border: 'none',
                    textAlign: 'center',
                    color: '#fff5eb',
                    background: 'rgba(0, 0, 0, 0.671)'
                },
            }}>
            <h2><IoClose onClick={handleCloseModal8} /></h2>
            <h2>O Salutaris (Agnes Zimmermann)</h2>
            <audio controls id="audio">
                <source src={OSalutaris} type="audio/mp3" />
            </audio>
        </ReactModal>
    </div>;
}

export default MobileAudios;
