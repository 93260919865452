import React from 'react';
import grupal from '../assets/grupal.jpeg';
import "../styles/Home.scss";

function Home() {
    return <div className='home' id='inicio'>
        <img src={grupal} alt='grupal' />
    </div>;
}

export default Home;
