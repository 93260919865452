import React, { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';
import "../styles/Contact.scss";

function Contact() {
  const formRef = useRef();
  const [done, setDone] = useState(false);

  const [input, setInput] = useState({
    user_name: "",
    user_subject: "",
    user_email: "",
    message: ""
  })

  function handleSubmit(e) {
    e.preventDefault();

    emailjs.sendForm('service_9mlha18', 'template_go9ktgg', formRef.current, 'user_qEeinarlL51GY5jqTeDQJ')
      .then((result) => {
        console.log(result.text);
        setDone(true);
        setInput({
          user_name: "",
          user_subject: "",
          user_email: "",
          message: ""
        })
      }, (error) => {
        console.log(error.text);
      });
  }

  function handleChange(e) {
    setInput(e.target.value);
  }

  return <div>
    <div className='contact' id="contacto">
      <h2 className='title'>Si desean contactarse con nosotros pueden hacerlo llenando el formulario</h2>
      <div className='formulario'>
        <form ref={formRef} onSubmit={handleSubmit}>
          <input type="text" placeholder="Nombre" name="user_name" value={input.user_name} onChange={handleChange} />
          <input type="text" placeholder="Asunto" name="user_subject" value={input.user_subject} onChange={handleChange} />
          <input type="email" placeholder="Email" name="user_email" value={input.user_email} onChange={handleChange} />
          <textarea placeholder="Mensaje" name="message" value={input.message} onChange={handleChange} />
          <button type="submit">Enviar</button>
          {done && "Mensaje enviado"}
        </form>
      </div>
      <h2>Gracias!</h2>
    </div>
  </div>;
}

export default Contact;
