import React from 'react';
import "../styles/Audios.scss";
import ScreenAudios from './ScreenAudios';
import MobileAudios from './MobileAudios';

function Audios() {
    return <div className='audios'>
        <ScreenAudios />
        <MobileAudios />
    </div>;
}

export default Audios;

