import React from 'react';
import '../styles/Videos.scss';

function CardVideo({ title, composer, year, src }) {
    return <div className='container'>
        <div className='video' key={title}>
            <h3>{title}</h3>
            <h3>{composer}</h3>
            <h3>{year}</h3>
            <iframe width="360" height="215" src={src} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </div>
    </div>;
}

export default CardVideo;
