import React from 'react';
import "../styles/Videos.scss";
import { videos } from '../data';
import Carousel from 'react-elastic-carousel';
import CardVideo from './CardVideo';

function Videos() {
  const breakPoints = [
    { width: 500, itemsToShow: 1 },
    { width: 768, itemsToShow: 2 },
    { width: 1200, itemsToShow: 3 },
    { width: 1500, itemsToShow: 4 }
  ]
  return <div className='videos'>
    <h2>Selección de videos en YouTube</h2>
    <div className='screenVideos'>
      <Carousel breakPoints={breakPoints} className='carousel'>
        {videos.map(e =>
          <CardVideo title={e.title} composer={e.composer} year={e.year} src={e.src} />)}
      </Carousel>
    </div>
  </div>
}

export default Videos;
