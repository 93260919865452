import React, { useState, useEffect } from 'react';
import '../styles/Navbar.scss';
import { BsFillPersonFill } from 'react-icons/bs';
import { Link as LinkS } from 'react-scroll';
import logo from '../assets/logo.png';
import { FiMenu } from 'react-icons/fi';
import { IoClose } from 'react-icons/io5';

function NavBar() {
    const [open, setOpen] = useState(false);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    const hamburgerIcon = <FiMenu onClick={() => setOpen(!open)} />
    const closeIcon = <IoClose onClick={() => setOpen(!open)} />

    useEffect(() => {
        const changeWidth = () => {
            setScreenWidth(window.innerWidth);
        }
        window.addEventListener('resize', changeWidth)

        return () => {
            window.removeEventListener('resize', changeWidth)
        }
    }, [])

    return (
        <nav className='navbar'>
            <LinkS to='inicio'>
                <img src={logo} alt='logo'/>
            </LinkS>
            {(open || screenWidth > 1300) &&
                (<ul>
                    <li>
                        <LinkS to='inicio' onClick={() => setOpen(false)}>
                            Inicio
                        </LinkS>
                    </li>
                    <li>
                        <LinkS to='nosotros' onClick={() => setOpen(false)}>
                            Acerca de nosotros
                        </LinkS>
                    </li>
                    <li>
                        <LinkS to='presentaciones' onClick={() => setOpen(false)}>
                            Presentaciones
                        </LinkS>
                    </li>
                    <li>
                        <LinkS to='repertorio' onClick={() => setOpen(false)}>
                            Repertorio
                        </LinkS>
                    </li>
                    <li>
                        <LinkS to='material' onClick={() => setOpen(false)}>
                            Material audiovisual
                        </LinkS>
                    </li>
                    <li>
                        <LinkS to='contacto' onClick={() => setOpen(false)}>
                            Contacto
                        </LinkS>
                    </li>
                    <li>
                        <a href='https://riodevoces.musica.ar/privado' target="_blank" onClick={() => setOpen(false)}>
                            <BsFillPersonFill />
                        </a>
                    </li>
                </ul>
                )}
            <div className='icon'>
                {open ? closeIcon : hamburgerIcon}
            </div>
        </nav>
    )
}

export default NavBar;
