export const concerts = [
    {
        id: 1,
        place: "Auditorio del Banco Río, Buenos Aires, concierto, primera presentación en público",
        date: "16/11/2000"
    },
    {
        id: 2,
        place: "Cine Teatro Cañuelas, Cañuelas, II Encuentro Coral",
        date: "27/10/2001"
    },
    {
        id: 3,
        place: "Parroquia San Luis Gonzaga, Buenos Aires, concierto",
        date: "16/12/2001"
    },
    {
        id: 4,
        place: "Iglesia Luterana La Reforma, Olivos, V Concierto Coral ACCERVIL",
        date: "30/08/2002"
    },
    {
        id: 5,
        place: "Parroquia San Luis Gonzaga, Buenos Aires, encuentro con el ensamble de instrumentos antiguos \"Goma Consort\"",
        date: "27/10/2002"
    },
    {
        id: 6,
        place: "Auditorio del Banco Río, Buenos Aires, actuación especial en la obra de teatro \"Para los hijos del amor\"",
        date: "2002"
    },
    {
        id: 7,
        place: "Auditorio del Banco Río, Buenos Aires, concierto",
        date: "18/12/2002"
    },
    {
        id: 8,
        place: "Parroquia Sta. Magdalena Sofía Barat, Castelar, encuentro coral",
        date: "3/08/2003"
    },
    {
        id: 9,
        place: "Congregación \"El Redentor\", Buenos Aires, encuentro coral con el coro \"Nuevo Pensar\"",
        date: "2/11/2003"
    },
    {
        id: 10,
        place: "Parroquia Jesús en el Huerto de los Olivos, Olivos, encuentro coral",
        date: "21/11/2003"
    },
    {
        id: 11,
        place: "Congregación \"La Cruz de Cristo\", Buenos Aires, encuentro coral con el grupo vocal \"Andantino\"",
        date: "30/11/2003"
    },
    {
        id: 12,
        place: "Auditorio Banco Río, Buenos Aires, encuentro coral con el Coro Polifónico de Ciegos",
        date: "18/12/2003"
    },
    {
        id: 13,
        place: "Escuela de Educación Técnica n.º 3, Zárate, 3er ciclo de encuentros en el marco del 150 aniversario de Zárate",
        date: "5/09/2004"
    },
    {
        id: 14,
        place: "Museo de la Reconquista, Tigre, Festival Río de Voces (a beneficio de LALCEC Tigre)",
        date: "2/10/2004"
    },
    {
        id: 15,
        place: "Museo de Arte Español \"Enrique Larreta\", Buenos Aires, concierto",
        date: "14/10/2004"
    },
    {
        id: 16,
        place: "Parroquia San Luis Gonzaga, Buenos Aires, concierto",
        date: "24/10/2004"
    },
    {
        id: 17,
        place: "Congregación \"El Redentor\", Buenos Aires, encuentro coral con \"Vocal in Musica\"",
        date: "24/11/2004"
    },
    {
        id: 18,
        place: "Iglesia Sta. Francisca Javier Cabrini, Buenos Aires, Festival Río de Voces (a beneficio del Hogar Belén)",
        date: "5/12/2004"
    },
    {
        id: 19,
        place: "Iglesia Ntra. Sra. del Carmelo, encuentro coral con \"Ensamble Coral del Buen Ayre\"",
        date: "14/12/2004"
    },
    {
        id: 20,
        place: "Círculo Italiano, Tigre, Festival Río de Voces (a beneficio de LALCEC Tigre)",
        date: "25/06/2005"
    },
    {
        id: 21,
        place: "La Boca, Buenos Aires, Concierto a beneficio de la Fundación Argentina de Trasplante Hepático",
        date: "31/07/2005"
    },
    {
        id: 22,
        place: "Parroquia Ntra. Sra. de Luján, Longchamps, Encuentro coral San Pío X (a beneficio de Cáritas Longchamps)",
        date: "7/08/2005"
    },
    {
        id: 23,
        place: "Parroquia San Luis Gonzaga, Buenos Aires, Encuentro coral con \"Voces del Itaú\"",
        date: "28/08/2005"
    },
    {
        id: 24,
        place: "Museo de Arte Español \"Enrique Larreta\", Buenos Aires, concierto",
        date: "8/09/2005"
    },
    {
        id: 25,
        place: "Parroquia Ntra. Sra. del Rosario de Pompeya (Castelar, GBA), 72º Encuentro Coral \"Castelar Canta\"",
        date: "11/09/2005"
    },
    {
        id: 26,
        place: "Congregación \"La Cruz de Cristo\", Buenos Aires, concierto",
        date: "7/10/2005"
    },
    {
        id: 27,
        place: "Museo de la Reconquista, Tigre, Festival Río de Voces (a beneficio de LALCEC Tigre)",
        date: "12/11/2005"
    },
    {
        id: 28,
        place: "La Boca, Buenos Aires, Concierto a beneficio de la Fundación Argentina de Trasplante Hepático",
        date: "20/11/2005"
    },
    {
        id: 29,
        place: "Parroquia San Benito Abad, Buenos Aires, encuentro coral con el Coro de la Municipalidad de Tres de Febrero",
        date: "4/12/2005"
    },
    {
        id: 30,
        place: "Parroquia del Buen Pastor, Buenos Aires, Encuentro Coral Ferro 2006",
        date: "24/06/2006"
    },
    {
        id: 31,
        place: "Catedral de Morón, Gran Buenos Aires, Ciclo de Arte Coral 2006",
        date: "23/07/2006"
    },
    {
        id: 32,
        place: "Primera Iglesia Evangélica Metodista, Buenos Aires, Ciclo Adicora 2006",
        date: "19/08/2006"
    },
    {
        id: 33,
        place: "UTN, Buenos Aires, encuentro coral con \"Coro y Camerata de la UTN\" y \"Coro Cantares del Caminante\"",
        date: "25/08/2006"
    },
    {
        id: 34,
        place: "Colegio Mekhitarista, Buenos Aires, Encuentro coral \"Cantando y soñando I\"",
        date: "28/10/2006"
    },
    {
        id: 35,
        place: "Museo de la Reconquista, Tigre, Festival Río de Voces (a beneficio de LALCEC Tigre)",
        date: "18/11/2006"
    },
    {
        id: 36,
        place: "Parroquia San Luis Gonzaga, Buenos Aires, encuentro coral con el coro \"Voces del Itaú\"",
        date: "26/11/2006"
    },
    {
        id: 37,
        place: "Parroquia Ntra. Sra. del Rosario de Pompeya, Castelar, 84º Encuentro Coral \"Castelar Canta\"",
        date: "10/12/2006"
    },
    {
        id: 38,
        place: "Villa General Belgrano, Córdoba, XII Encuentro Coral del Valle",
        date: "15/09/2007"
    },
    {
        id: 39,
        place: "Capilla San Roque, Buenos Aires, encuentro coral con Coral Pilar – St. John’s",
        date: "25/10/2007"
    },
    {
        id: 40,
        place: "Capilla San Roque, Buenos Aires, encuentro coral con el Coro \"Santa Cecilia\"",
        date: "22/11/2007"
    },
    {
        id: 41,
        place: "Teatro LYF (Luz y Fuerza), Buenos Aires, Encuentros corales LyF 2007",
        date: "2/12/2007"
    },
    {
        id: 42,
        place: "Parroquia San Luis Gonzaga, Buenos Aires, encuentro coral con \"Voces del Itaú\"",
        date: "9/12/2007"
    },
    {
        id: 43,
        place: "Capilla San Roque, Buenos Aires, encuentro coral con el Octeto Voz por vos, el Coro de la Facultad de Sicología de la UBA y la Agrupación vocal Lado B",
        date: "20/07/2008"
    },
    {
        id: 44,
        place: "Parroquia San Luis Gonzaga, Buenos Aires",
        date: "31/08/2008"
    },
    {
        id: 45,
        place: "Teatro Municipal \"Tomas Seminari\", encuentro coral con el Coral St.Patrik's, Coro de la Facultad Regional Delta - UTN y Coro \"Rinascere\"",
        date: "27/09/2008"
    },
    {
        id: 46,
        place: "Almacén Cultural Cooperativo, Zárate, encuentro coral con el Coro del Almacén Cultural Cooperativo y el Coro \"Rinascere\"",
        date: "11/10/2008"
    },
    {
        id: 47,
        place: "Parroquia La Sagrada Familia, Ciudad Jardín, encuentro coral con el Coro de Niños San José y el Coro de la Parroquia Sagrada Familia",
        date: "18/10/2008"
    },
    {
        id: 48,
        place: "Capilla Santa Teresita, Tortuguitas, encuentro coral con el Coro Tortugas y el Coro de Benavidez",
        date: "13/12/2008"
    },
    {
        id: 49,
        place: "Centro de jubilados y pensionados \"4 de Noviembre\", Monte Grande",
        date: "21/12/2008"
    },
    {
        id: 50,
        place: "Instituto geriátrico \"Chatelet\", Buenos Aires",
        date: "27/12/2008"
    },
    {
        id: 51,
        place: "Centro cultural del colegio Mekhitarista de Buenos Aires, encuentro coral con el Coral Vivace y el Coro de ex alumnos del Villa Devoto School",
        date: "29/04/2009"
    },
    {
        id: 52,
        place: "Encuentro coral en el Tigre",
        date: "16/10/2009"
    },
    {
        id: 53,
        place: "Festival Adicora",
        date: "25/10/2009"
    },
    {
        id: 54,
        place: "Parroquia San Cayetano, Villa Adelina, encuentro coral \"Buenos Aires canta\", con el coro Acuarela y el Coro del Instituto Ballester",
        date: "31/10/2009"
    },
    {
        id: 55,
        place: "Capilla San Roque, encuentro por el día de la música, con el \"Coro de la Facultad de Agronomía\" y el \"Cordal Trío\"",
        date: "22/11/2009"
    },
    {
        id: 56,
        place: "Catedral y basílica \"Nuestra Señora de la Paz\", Lomas de Zamora, encuentro coral \"200 años, 200 voces\" en el marco del bicentenario nacional",
        date: "9/07/2010"
    },
    {
        id: 57,
        place: "Centro de jubilados y pensionados \"4 de Noviembre\", Monte Grande",
        date: "24/07/2010"
    },
    {
        id: 58,
        place: "Colegio Mekhitarista, Buenos Aires, Encuentro coral \"Cantando y soñando II\", con el Coral del Roble del Instituto Monseñor Dillon y Ensamble Coral",
        date: "18/08/2010"
    },
    {
        id: 59,
        place: "Auditorio de la Fundación San Rafael, Evento Coral y musical, organizado por el grupo Aiken, junto al \"Coro de la Facultad de Psicología de la UBA\", dúo de guitarras \"Hoyos-Macchi\", y el trío integrado por María Eugenia Castro (cello), Sergio Polizzi (violín) y Sandra Aquaviva (flauta)",
        date: "31/10/2010"
    },
    {
        id: 60,
        place: "Fiesta Coral 2010 Bicentenario (ADICORA), \"Canta Calle\" y concierto en la parroquia \"Patrocinio de San José\" junto con el \"Coro de la Facultad de Psicología de la UBA\" y el \"Coro de la Universidad de Belgrano\"",
        date: "23/10/2010"
    },
    {
        id: 61,
        place: "Encuentro coral en el colegio \"Evskal-Etxea\" de Lavallol, junto a los coros Euskal Echea de Lavallol y Capital, Vocal Taiel y Coral San Vicente",
        date: "06/11/2010"
    }
]

export const repertory = [
    {
        title: "A cantar a una niña (pop. chileno; arr. M.ª del Carmen Aguilar)"
    },
    {
        title: "Abschied vom Wald (Felix Mendelssohn)"
    },
    {
        title: "Agnus Dei (Grancini)"
    },
    {
        title: "Alma llanera (arr. Liliana Cangiano)"
    },
    {
        title: "Amarraditos (M. Durán/P. Pérez; arr. Marcelo Delgado)"
    },
    {
        title: "Anhelo (Carlos Guastavino)"
    },
    {
        title: "Ave María (Jacobo Arcadelt)"
    },
    {
        title: "Ave verum corpus (Charles Gounod)"
    },
    {
        title: "Ave verum corpus (W.A. Mozart)"
    },
    {
        title: "Befiehl Du Deine Wege (J.S. Bach)"
    },
    {
        title: "Brüder reicht die Hand zum Bunde (W.A. Mozart)"
    },
    {
        title: "Canción de cuna (Clemen Fraga Moreira, arr. Alberto Ream)"
    },
    {
        title: "Canon (J. Pachelbel, arr. D. Huerin)"
    },
    {
        title: "Ciego quisiera haber sido (Carlos Guastavino)"
    },
    {
        title: "Come again sweet love (John Dowland)"
    },
    {
        title: "Contrapunto bestiale alla mente"
    },
    {
        title: "Cuando nada te debía (anónimo; arr. Liliana Cangiano)"
    },
    {
        title: "Chacarera doble (Urbistondo)"
    },
    {
        title: "De Cuba para La Habana (anónimo; arr. Electo Silva)"
    },
    {
        title: "Domine Salvam (Charles Gounod)"
    },
    {
        title: "Doña Ubensa (arr. Liliana Cangiano)"
    },
    {
        title: "El grillo è buon cantore (Josquin des Près)"
    },
    {
        title: "En los surcos del amor (Carlos Guastavino)"
    },
    {
        title: "Hanacpachap cussicuinin (anónimo)"
    },
    {
        title: "Heilig /Zum Sanctus (Franz Schubert)"
    },
    {
        title: "Himno a la alegría (Ludwig van Beethoven)"
    },
    {
        title: "Himno Río de Voces (M. García/J. Pautasso)"
    },
    {
        title: "Hoy comamos y bebamos (Juan del Encina)"
    },
    {
        title: "Les anges dan nos campagnes (popular siglo XVI)"
    },
    {
        title: "Mi pueblo chico"
    },
    {
        title: "Non nobis, Domine (William Byrd)"
    },
    {
        title: "O Occi Manza Mia"
    },
    {
        title: "O salutaris hostia (Agnes M. J. Zimmermann)"
    },
    {
        title: "Pampamapa (Carlos Guastavino)"
    },
    {
        title: "Profecías (Vox Dei; arr. Dadi López)"
    },
    {
        title: "Rumi cani /Piedra soy (Peteco Carabajal; arr. Vivian Tabbush)"
    },
    {
        title: "Trabajos de la viña (letra: Jorge Sosa, música: Damián Sánchez)"
    },
    {
        title: "Tuyo he'y de ser (arr. Marcelo Ortiz Roca)"
    },
    {
        title: "Una pena nuevamente (Carlos Guastavino)"
    }
]

export const videos = [
    {
        title: "Ave Maria",
        composer: "Jacobo Arcadelt",
        year: "Diciembre 2010",
        src: "https://www.youtube.com/embed/v0mDYcRLASM"
    },
    {
        title: "Agnus Dei",
        composer: "Michelangelo Grancini",
        year: "Diciembre 2010",
        src: "https://www.youtube.com/embed/1yXlSeay3nA"
    },
    {
        title: "Una pena nuevamente",
        composer: "Carlos Guastavino",
        year: "Diciembre 2010",
        src: "https://www.youtube.com/embed/WF9XViif-ig"
    },
    {
        title: "Poema 20",
        composer: "Pablo Neruda - Ramón Ayala",
        year: "Noviembre 2009",
        src: "https://www.youtube.com/embed/haNO89v9qA0"
    },
    {
        title: "Come Again",
        composer: "John Dowland",
        year: "Octubre 2008",
        src: "https://www.youtube.com/embed/VaiImdT2_O0"
    },
    {
        title: "A cantar a una niña",
        composer: "anónimo",
        year: "Octubre 2008",
        src: "https://www.youtube.com/embed/szYkT18mFnM"
    },
    {
        title: "Anhelo",
        composer: "Carlos Guastavino",
        year: "Octubre 2008",
        src: "https://www.youtube.com/embed/ni4-YnGO5xs"
    },
    {
        title: "Pampamapa",
        composer: "Carlos Guastavino (de: Canciones populares)",
        year: "Octubre 2007",
        src: "https://www.youtube.com/embed/vE9h2eFvDok"
    },
    {
        title: "Hoy comamos y bebamos",
        composer: "Juan del Encina",
        year: "Octubre 2007",
        src: "https://www.youtube.com/embed/EXrEWT8CTzw"
    },
    {
        title: "Despedida del bosque (Abschied vom Wald)",
        composer: "Mendelssohn",
        year: "Octubre 2007",
        src: "https://www.youtube.com/embed/-s1ifbtwXeg"
    },
    {
        title: "Ave Verum Corpus",
        composer: "Wolfgang Amadeus Mozart ",
        year: "Octubre 2007",
        src: "https://www.youtube.com/embed/9fjTOdWgcqA"
    },
    {
        title: "Ave Verum Corpus",
        composer: "Charles Gounod",
        year: "Octubre 2007",
        src: "https://www.youtube.com/embed/B5a8h66vjko"
    },
    {
        title: "O Salutaris Hostia",
        composer: "Agnes Zimmernann",
        year: "Octubre 2007",
        src: "https://www.youtube.com/embed/-Ke3aVwlZDI"
    },
    {
        title: "Heilig",
        composer: "Franz Schubert",
        year: "Octubre 2017",
        src: "https://www.youtube.com/embed/BQE1LaEoI0Q"
    }
]