import React from 'react';
import "../styles/Presentations.scss";
import MobilePresentations from './MobilePresentations';
import ScreenPresentations from './ScreenPresentations';

function Presentations() {
  return <div className='presentations' id="presentaciones">
    <MobilePresentations />
    <ScreenPresentations />
  </div>;
}

export default Presentations;
