import React from 'react';
import css from '../styles/About.module.scss';
import Alba from '../assets/members/Alba.png';
import Debora from '../assets/members/Debora.png';
import Hugo from '../assets/members/Hugo.png';
import Isabel from '../assets/members/Isabel.png';
import Jose from '../assets/members/Jose.png';
import Luisa from '../assets/members/Luisa.png';
import Osvaldo from '../assets/members/Osvaldo.png';

function About() {
    return <div className={css.containerAbout} id='nosotros'>
        <div className={css.source}>
            <h2>Origen</h2>
            <p>
                El coro de cámara Río de Voces nace en el año 2000 como un proyecto de integración para los empleados de una reconocida institución bancaria. A partir del 2006 es un coro autogestivo. Inicia en 2008 una nueva etapa, incorporando nuevas voces y confiando la dirección coral y artística a un nuevo director.<br /> <br />
                Se ha presentado en varios recitales y encuentros corales en la Ciudad de Buenos Aires, localidades del Gran Buenos Aires, e interior del país, interpretando a capella un variado repertorio de composiciones clásicas y populares.
            </p>
        </div>
        <div className={css.members}>
            <h2>Integrantes</h2>
            <div className={css.screenMembers}>
                <div className={css.container}>
                    <div className={css.firstSection}>
                        <div className={css.section}>
                            <h3>Sopranos</h3>
                            <div className={css.memberName}>
                                <div className={css.member}>
                                    <p>Débora Bataglini</p>
                                    <img src={Debora} alt='Debora'/>
                                </div>
                                <div className={css.member}>
                                    <p>Luisa Ferrarini</p>
                                    <img src={Luisa} alt='Luisa'/>
                                </div>
                            </div>
                        </div>

                        <div className={css.section}>
                            <h3>Contraltos</h3>
                            <div className={css.memberName}>
                                <div className={css.member}>
                                    <p>Isabel Cid</p>
                                    <img src={Isabel} alt='Isabel'/>
                                </div>
                                <div className={css.member}>
                                    <p>Alba Rodríguez</p>
                                    <img src={Alba} alt='Alba'/>
                                </div>
                            </div>
                        </div>

                        <div className={css.section}>
                            <h3>Tenores</h3>
                            <div className={css.memberName}>
                                <div className={css.member}>
                                    <p>Osvaldo Bodratti</p>
                                    <img src={Osvaldo} alt='Osvaldo'/>
                                </div>
                            </div>
                        </div>

                        <div className={css.section}>
                            <h3>Bajos</h3>
                            <div className={css.memberName}>
                                <div className={css.member}>
                                    <p>José Luis González</p>
                                    <img src={Jose} alt='Jose'/>
                                </div>
                                <div className={css.member}>
                                    <p>Hugo Loustalet</p>
                                    <img src={Hugo} alt='Hugo'/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={css.secondSection}>
                        <div className={css.section}>
                            <h3>Dirección y preparación vocal</h3>
                            <div className={css.memberName}>
                                <div className={css.member}>
                                    <p>Hugo Loustalet</p>
                                    <img src={Hugo} alt='Hugo'/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className={css.mobileMembers}>
                <div className={css.container}>
                    <div className={css.section}>
                        <h3>Sopranos</h3>
                        <div className={css.memberName}>
                            <div className={css.member}>
                                <p>Débora Bataglini</p>
                                <img src={Debora} alt='Debora'/>
                            </div>
                            <div className={css.member}>
                                <p>Luisa Ferrarini</p>
                                <img src={Luisa} alt='Luisa'/>
                            </div>
                        </div>
                    </div>

                    <div className={css.section}>
                        <h3>Contraltos</h3>
                        <div className={css.memberName}>
                            <div className={css.member}>
                                <p>Isabel Cid</p>
                                <img src={Isabel} alt='Isabel'/>
                            </div>
                            <div className={css.member}>
                                <p>Alba Rodríguez</p>
                                <img src={Alba} alt='Alba'/>
                            </div>
                        </div>
                    </div>

                    <div className={css.section}>
                        <h3>Tenores</h3>
                        <div className={css.memberName}>
                            <div className={css.member}>
                                <p>Osvaldo Bodratti</p>
                                <img src={Osvaldo} alt='Osvaldo'/>
                            </div>
                        </div>
                    </div>

                    <div className={css.section}>
                        <h3>Bajos</h3>
                        <div className={css.memberName}>
                            <div className={css.member}>
                                <p>José Luis González</p>
                                <img src={Jose} alt='Jose'/>
                            </div>
                            <div className={css.member}>
                                <p>Hugo Loustalet</p>
                                <img src={Hugo} alt='Hugo'/>
                            </div>
                        </div>
                    </div>
                    <div className={css.section}>
                        <h3>Dirección y preparación vocal</h3>
                        <div className={css.memberName}>
                            <div className={css.member}>
                                <p>Hugo Loustalet</p>
                                <img src={Hugo} alt='Hugo'/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default About;
