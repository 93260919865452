import About from './components/About';
import Audiovisual from './components/Audiovisual';
import Contact from './components/Contact';
import Home from './components/Home';
import Navbar from './components/Navbar';
import Presentations from './components/Presentations';
import Repertory from './components/Repertory';
import { BrowserRouter } from 'react-router-dom';

function App() {
  return (
    <div >
      <BrowserRouter>
        <Navbar />
          <Home />
          <About />
          <Presentations />
          <Repertory />
          <Audiovisual />
          <Contact />
      </BrowserRouter>
    </div>
  );
}

export default App;
