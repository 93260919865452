import React from 'react';
import Audios from './Audios';
import Videos from './Videos';
import "../styles/Audiovisual.scss";

function Audiovisual() {
  return <div className='audiovisual' id='material'>
    <h2 className='title'>Selección de archivos en formato mp3</h2>
    <Audios />
    <Videos />
  </div>;
}

export default Audiovisual;
