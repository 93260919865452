import React, { useState } from 'react';
import "../styles/Repertory.scss";
import { repertory } from '../data';
import Pagination from './Pagination';

function MobileRepertory() {
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(9);

    const max = Math.round(repertory.length / perPage);

    return <div className='mobileRepertory'>
        <h2>Lista de obras que Río de Voces ha ejecutado en el pasado o que prepara en la actualidad (por orden alfabético)</h2>
        {repertory
            .slice((page - 1) * perPage, (page - 1) * perPage + perPage)
            .map(e => (
                <div key={e.title} className='containerRepertory'>
                    <ul>
                        <li>{e.title}.</li>
                    </ul>
                </div>
            ))}
        <Pagination page={page} setPage={setPage} max={max} />
    </div>;
}

export default MobileRepertory;
