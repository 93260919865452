import React, { useState } from 'react';
import "../styles/Presentations.scss";
import { concerts } from '../data';
import Pagination from './Pagination';

function MobilePresentations() {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(6);

  const max = Math.round(concerts.length / perPage);

  return <div className='mobilePresentations' id="presentaciones">
    <h2>Lista de conciertos, recitales y otras presentaciones públicas de Río de Voces hasta la fecha</h2>
    {concerts
      .slice((page - 1) * perPage, (page - 1) * perPage + perPage)
      .map(e => (
        <div key={e.id} className='containerPresentations'>
          <ul>
            <li>{e.date}, {e.place}.</li>
          </ul>
        </div>
      ))}
    <Pagination page={page} setPage={setPage} max={max} />
  </div>
}

export default MobilePresentations;

